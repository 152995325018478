
/* Transition */
.flights-enter {
    opacity: 0.01;
  }
  .flights-enter.flights-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .flights-exit {
    opacity: 1;
  }
  .flights-exit.flights-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }